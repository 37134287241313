@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;
     
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
     
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
     
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
     
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
     
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
     
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
    }
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}

